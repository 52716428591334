import React, { useState } from 'react';
import './HospitalDepartments.css'
import Doctors from '../Doctors/Doctors';
import SpinnerMiddle from '../../Spinner/spinnerMiddle';

const HospitalDepartments = ({ hospitalData }) => {

    let [selectedDepartment, setSelectedDepartment] = useState('');
    let bgColor = '#fff';
    let allDepartments = [];

    for (let i = 0; i < hospitalData?.department?.length; i++) {
        if (!allDepartments.includes(hospitalData?.department[i]?.department)) {
            allDepartments.push(hospitalData?.department[i]?.department)
        }
    }


    // const doctorsData = (hospitalData?.department[firstDepartment]);
    let doctorsData = hospitalData?.department?.filter(data => data?.department === selectedDepartment);
    const handleSelectValue = (selectedDepartment) => {
        setSelectedDepartment(selectedDepartment);
        console.log(selectedDepartment);
    }

    return (
        <div className={!selectedDepartment ? "departments-hospital" : "doctors"} style={{ background: bgColor }}>
            <div>
                {

                    !selectedDepartment ?
                        <div className="container">
                            <h1 className='text-center header-hospital-txt'>
                                {hospitalData?.name}&nbsp;
                                HOSPITAL DEPARTMENTs
                            </h1>
                        </div >
                        :

                        <div className='container'>
                            <h1 style={{margin: '5rem auto 0 auto', fontSize: '1.5rem', textAlign: 'center'}} className="header-hospital-txt">
                                {hospitalData?.name}&nbsp;
                                HOSPITAL DEPARTMENTs

                            </h1>
                            <Doctors doctorsData={doctorsData} />
                        </div>

                }

                <div className='hospital_txt hospital_txt_2'>
                    Select Your <br /> Required Department
                </div>

                <div className='spinner-middle-cont'>
                    {/* data-aos="fade-up" */}

                    <SpinnerMiddle info={allDepartments}
                        handleClick={
                            (data) => {
                                handleSelectValue(data);
                            }
                        }

                    />
                </div>

            </div>

        </div >

    );
};

export default HospitalDepartments;