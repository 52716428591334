import Header from "../../Shared/Header/Header";
import about from '../../assets/Images/about.jpg';
import aboutMobile from '../../assets/Images/aboutMobile.jpg';
import aboutMobile2 from '../../assets/Images/about2.jpg';

export function About() {
    return (
        <>
            <Header />

            <div style={{
                zIndex: '10',
                position: 'relative',
                margin: 'auto'
            }}>
                <div style={{
                    content: ' ',
                    marginTop: '4.5rem'
                }}>
                </div>
                <picture>
                    <source
                        media="(max-width: 1000px)"
                        srcset={aboutMobile} />
                    <img src={about} style={{
                        width: '100%'
                    }} />
                </picture>
            </div>

            <div style={{
                zIndex: '10',
                position: 'relative',
                margin: 'auto'
            }}>
                <img src={aboutMobile2} style={{
                    width: '100%'
                }} />
            </div>



        </>
    );
}