import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
// import Header from './Shared/Header/Header';
import OurHospitals from './components/Hospitals/OurHospitals/OurHospitals';
import OurHotels from './components/Hotels/OurHotels/OurHotels';
import Form from './components/Form/Form';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Test from './components/Test/Test';
import Login from './components/Admin/Login/login';
import Dashboard from './components/Admin/AdminPanel/dashboard';
import { About } from './components/About/About';
import { useCollection } from './Hooks/useCollection';
import { useEffect } from 'react';
import React from 'react';

function App() {
  const { document } = useCollection('Users');

  useEffect(
    () => {
      if (document.length !== 0) {
        localStorage.setItem('credentials', JSON.stringify(document));
      }
    }, [document]
  );

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop></ScrollToTop>
        {/* <Header /> */}

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/hospital' element={<OurHospitals />} />
          <Route path='/hotel' element={<OurHotels />} />
          <Route path='/form' element={<Form />} />
          <Route path='/test' element={<Test />} />
          <Route path='/login' element={<Login />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/about' element={<About />} />
        </Routes>


      </BrowserRouter>
    </div>
  );
}

export default App;