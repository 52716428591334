import React, { useState, useEffect } from 'react';
import './OurHotels.css';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import SpinnerCorner from '../../Spinner/spinnerCorner';
import NearestHotels from './../NearestHotels/NearestHotels';
import Header from './../../../Shared/Header/Header';
import messageIcon from "../../../assets/Images/message-icon.png";

const OurHotels = () => {

    let [hospitalData, setHospitalData] = useState([]);
    let [bgColor, setBgColor] = useState('');
    let [allPagesVisibility, setAllPagesVisibility] = useState(false);
    let [selectedHospital, setSelectedHospital] = useState({});
    let [mouse_busy, setMouse_busy] = useState(false);
    const myRef = React.createRef();


    useEffect(
        () => {
            const ref = collection(db, 'Hospitals');
            const q = query(ref, orderBy('index', 'desc'));
            let items = [];
            getDocs(q).then(
                (querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        items.push({ ...doc.data(), id: doc.id });
                    });
                    setHospitalData(items);
                }
            );
        }, []
    );


    const handleSelectValue = (selectedHospital) => {
        setSelectedHospital(selectedHospital);
    }

    const HandleHoverButtonClick = (bg_color) => {
        setAllPagesVisibility(!allPagesVisibility);
        setBgColor(bg_color);
    }

    useEffect(
        () => {
            if (allPagesVisibility) {
                myRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            }
        }, [allPagesVisibility]
    );

    return (
        <>
            <Header />
            <div className="OurHotels">
                {
                    hospitalData.length !== 0 ? <div className='hotel_txt hotel_txt_size'>
                        Hotels Nearby <br /> Your Hospital
                    </div> : <></>
                }
                {

                    !selectedHospital?.id ?
                        <>
                            <div className="container pt-5">
                                <div className="col-12 pt-5" >
                                    <div className='pt-5'>
                                        <h3>Our Hotels</h3>
                                        <h4>Find out the most comfortable and cost-effective hotel options.</h4>
                                    </div>
                                </div>

                            </div>

                        </> : <div className='map_container'>

                            <div className="mapContainer iFrameContainer mapContainerLock">
                                <div style={{ width: '100vw', height: '100vh' }}>
                                    <iframe onMouseLeave={
                                        () => {
                                            setMouse_busy(false);
                                        }
                                    }
                                        onMouseOver={() => {
                                            setMouse_busy(true);
                                        }}
                                        id='mapIframe' src={selectedHospital?.map} width="100%" height="100%" title='Hotel and Hospital Map'></iframe>
                                </div>
                            </div>

                            <div className='bottom-map-img'>
                            </div>

                        </div>
                }


                <div className="spin-wheel-corner">

                    {
                        hospitalData.length !== 0 ?
                            <SpinnerCorner info={hospitalData}
                                mouse_is_busy={mouse_busy}
                                handleClick={
                                    (bg_color, _) => {
                                        HandleHoverButtonClick(bg_color);
                                    }
                                }

                                handleHover={
                                    (data) => {
                                        handleSelectValue(data);
                                    }
                                }

                            /> :
                            <></>
                    }

                </div>

            </div>

            <div ref={myRef}>
                {
                    allPagesVisibility ?
                        <NearestHotels data={selectedHospital} bg_color={bgColor} /> : <></>
                }
            </div>

            <a href="https://p5jfzp15q2p.typeform.com/to/iAHQysg7" target="_blank" id="fixedbutton-link">
                <button className='btn-shadow' id='fixedbutton'>
                    <img src={messageIcon} className='img-fluid' alt="" />
                </button>
            </a>
        </>
    );
};

export default OurHotels;
