import { useEffect, useState, useRef } from "react";
import { collection, onSnapshot, query, where, orderBy } from 'firebase/firestore';
import { db } from '../firebase.config';

export const useCollection = (collectionName, _query, _orderBy) => {
    const [document, setDocument] = useState([]);
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let ref = collection(db, collectionName);
        ref = query(ref, orderBy("creatingDate", "desc"));

        setIsLoading(true);

        const unSubscribe = onSnapshot(ref, (snapshot) => {
            let result = [];
            snapshot.docs.forEach(doc => {
                result.push({ ...doc.data(), id: doc.id });
            });
            setDocument(result);
            setIsLoading(false);
        })

        return () => unSubscribe()
    }, [collectionName]);

    return { document, isLoading };
};

