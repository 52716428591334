import React from 'react';
import './HospitalMapGostDiv.css'

const HospitalMapGostDiv = () => {

    return (
        <div>
            <div className="HospitalMapGostDiv" id="HospitalMap">
            </div>
        </div>
    );
};

export default HospitalMapGostDiv;