import React, { useState, useEffect } from 'react';
import BookingModal from '../BookingModal/BookingModal';
import './Rooms.css'
import { useRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef } from 'react';


const Rooms = ({ marginBottom, roomData, HotelData, bg_color }) => {
    let margin = null;

    const roomName = (roomData[0]?.name);
    const HotelName = (HotelData?.name);
    let bookBtn = useRef('');

    marginBottom ? margin = 'auto auto 18rem auto' : margin = '5rem auto 10rem auto';

    let [error, setError] = useState("");
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const DateInput = forwardRef(({ value, onClick }, ref) => (
        <input type='text' class="form-control py-4" value={value} onClick={onClick} ref={ref} />
    ));

    let [bookData, setBookData] = useState({
        checkInDate: "",
        checkOutDate: "",
        numOfAdult: "",
        numOfChild: "",
        numOfRoom: "",
        name: "",
        number: "",
        email: "",
        roomName,
        HotelName
    });

    useEffect(
        () => {
            if (bookData['checkInDate'] !== '' && bookData['checkOutDate'] !== '') {
                let d1 = new Date(bookData['checkInDate']);
                let d2 = new Date(bookData['checkOutDate']);

                console.log(d1, d2);

                if (d2.getTime() < d1.getTime()) {
                    setError("Check out date must be after Check in date!");
                    bookBtn.current.disabled = true;
                }
                else {
                    setError("");
                    bookBtn.current.disabled = false;
                }
            }

        }, [bookData['checkInDate'], bookData['checkOutDate']]
    );



    const localData = [
        {
            "roomImage": 'https://firebasestorage.googleapis.com/v0/b/hotelsandhospitals-9aa7b.appspot.com/o/Dummy%2FImages%2F1%20(1).jpg?alt=media&token=8d8b5031-e984-4ec1-80ae-62301404edde',
            'name': 'Gold',
            'NumberOfRoom': '2',
            'Description': 'Room Description',
        },
        {
            "roomImage": 'https://firebasestorage.googleapis.com/v0/b/hotelsandhospitals-9aa7b.appspot.com/o/Dummy%2FImages%2F1%20(2).jpg?alt=media&token=510857f4-5793-4f07-b0b5-56534bd0475d',
            'name': 'Silver',
            'NumberOfRoom': '2',
            'Description': 'Room Description',
        },
        {
            "roomImage": 'https://firebasestorage.googleapis.com/v0/b/hotelsandhospitals-9aa7b.appspot.com/o/Dummy%2FImages%2F1%20(3).jpg?alt=media&token=510857f4-5793-4f07-b0b5-56534bd0475d',
            'name': 'Diamond',
            'NumberOfRoom': '2',
            'Description': 'Room Description',
        },
        {
            "roomImage": 'https://firebasestorage.googleapis.com/v0/b/hotelsandhospitals-9aa7b.appspot.com/o/Dummy%2FImages%2F1%20(4).jpg?alt=media&token=510857f4-5793-4f07-b0b5-56534bd0475d',
            'name': 'Platinum',
            'NumberOfRoom': '3',
            'Description': 'Room Description',
        },
        {
            "roomImage": 'https://firebasestorage.googleapis.com/v0/b/hotelsandhospitals-9aa7b.appspot.com/o/Dummy%2FImages%2F1%20(5).jpg?alt=media&token=510857f4-5793-4f07-b0b5-56534bd0475d',
            'name': 'Bronze',
            'NumberOfRoom': '2',
            'Description': 'Room Description',
        },
    ]

    return (
        <div className="rooms" style={{ backgroundColor: "#fff" }}>
            <>

                <div className="col-12">
                    <div class="container BookingContainer" style={{ margin: margin }}>
                        <div class="col-md-12 d-flex p-2  justify-content-center p-0">

                            <div className='p-3 px-5 bookRoom text-center'>
                                <h2>Book Your Room</h2>
                            </div>

                        </div>
                        <div class="hotel_booking_table d-flex p-5  justify-content-center">

                            <div class="row align-items-center">

                                <div className="col-12 col-md-3">
                                    <div className=' text-center'>
                                        <h3 style={{ color: "#FFCB08" }}>{HotelName}</h3>
                                    </div>

                                    <div className=' text-center'>
                                        <h5 style={{ color: "#FFCB08" }}>{roomName}</h5>
                                    </div>
                                </div>

                                <div className="col-md-9 col-12">
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <div className='text-center'>
                                                <label className="label">&nbsp; Check In date</label>
                                                <DatePicker
                                                    inputStyle={{ textAlign: 'center' }}
                                                    selected={startDate}
                                                    minDate={new Date()}
                                                    customInput={<DateInput />}
                                                    onChange={(date) => {
                                                        setBookData({ ...bookData, checkInDate: date });
                                                        setStartDate(date);
                                                    }}
                                                />
                                                {/* <input onChange={e => setBookData({ ...bookData, checkInDate: e.target.value })} type='date' class="form-control py-4" /> */}
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-3">
                                            <div className='text-center'>
                                                <label className="label">Check out date</label>

                                                <DatePicker
                                                    inputStyle={{ textAlign: 'center'  }}
                                                    selected={endDate}
                                                    minDate={new Date()}
                                                    customInput={<DateInput />}
                                                    onChange={(date) => {
                                                        setBookData({ ...bookData, checkOutDate: date });
                                                        setEndDate(date);
                                                    }}
                                                />


                                                {/* <input onChange={e => setBookData({ ...bookData, checkOutDate: e.target.value })} type='date' class="form-control  py-4" /> */}
                                            </div>
                                        </div>


                                        <div className="col-12 col-md-2">
                                            <div class="text-center ">
                                                <label className="label" htmlFor="">Number of Adult</label>
                                                <input style={{ textAlign: "center" }} onChange={e => setBookData({ ...bookData, numOfAdult: e.target.value })} type='number' min="0" class="form-control  py-4" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-2">
                                            <div class=" text-center">
                                                <label className="label" htmlFor="">Number of Child</label>
                                                <input style={{ textAlign: "center" }} onChange={e => setBookData({ ...bookData, numOfChild: e.target.value })} type='number' min="0" class="form-control py-4" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-2">
                                            <div class="text-center ">
                                                <label className="label" htmlFor="">Number of Rooms</label>
                                                <input style={{ textAlign: "center" }} onChange={e => setBookData({ ...bookData, numOfRoom: e.target.value })} type='number' min="0" class="form-control  py-4" />
                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-md-12 d-flex  justify-content-center'>
                            <button data-bs-toggle="modal" ref={bookBtn} id='bookBtn'
                                data-bs-target="#EditModal"
                                className="bookingSubmitButton btn p-3 m-2">Book Now</button>
                        </div>
                        {
                            error && <p style={{ color: "orange", fontSize: "1.2rem", textAlign: "center", margin: "1rem 1rem 2rem 1rem" }}>{error}</p>
                        }
                    </div>
                </div>



            </>

            <BookingModal setBookData={setBookData} bookData={bookData}></BookingModal>
        </div >
    );
};

export default Rooms;