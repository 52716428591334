import { Link } from "react-router-dom";
import hospitalIcon from "./../../../assets/Images/LandingPage/med_wing.png";
import hotelIcon from "./../../../assets/Images/LandingPage/hospital_wing.png";
import "./SelectHotelsOrHospital.css";
import hospitalBtn from "./../../../assets/Images/LandingPage/btn-hospital.png";
import hotelBtn from "./../../../assets/Images/LandingPage/btn-hotel.png";
import React from "react";
import banglaLogo from "../../../assets/Images/new_Logo4.png";
// import banglaLogo from "../../../assets/Images/curaBangla.PNG";
// import englishLogo from "../../../assets/Images/curaEnglish.PNG"
import homeBottom from "../../../assets/Images/homeBottom.jpeg";

const SelectHotelsOrHospital = () => {
  return (
    <div className="_SelectHotelsOrHospital text-center">
      <div>
        <div className="absolute_img">
          <img src={banglaLogo} alt="logo" />
        </div>
        <div className="mx-auto row row-cols-1 row-cols-md-4 g-5 justify-content-center cardsContainer">
          <div
            className="col d-flex justify-content-center cardHolder"
            data-aos="fade-left"
          >
            <div className="card-left card h-100 HospitalContainer">
              <div className="card-body">
                <p className="card-text text-white">
                  Discover the best hospital to treat your ailment
                </p>
              </div>

              <img src={hospitalIcon} className="home-icon" alt="..." />

              <div className="card-footer">
                <Link to="/hospital">
                  <img
                    src={hospitalBtn}
                    alt="Hospital"
                    className="selectionBtn"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div
            className="col d-flex justify-content-center cardHolder"
            data-aos="fade-right"
          >
            <div className="card-right card h-100 HotelContainer">
              <div className="card-body">
                <p className="card-text text-white">
                  Discover the best hotel to make your journey worthwhile.
                </p>
              </div>

              <img src={hotelIcon} className="home-icon" alt="..." />

              <div className="card-footer">
                <Link to="/hotel">
                  <img src={hotelBtn} alt="Hotel" className="selectionBtn" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 pt-5" style={{ width: "100%" }}>
          <div className="col-md-12">
            <img className="bottom_img" src={homeBottom} alt="logo" />
          </div>
          {/* <div className='col-md-6'>
                        <img style={{width: "100%"}}  src={banglaLogo} alt="logo" />
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default SelectHotelsOrHospital;
