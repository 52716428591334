import React from 'react';
import './spinnerCornerStyle.css';

class SpinnerCorner extends React.Component {
    constructor(props) {
        super(props);
        this.menus = [];
        this.is_hovered = false;
        this.prev_btn = React.createRef('');
        this.next_btn = React.createRef('');
        this.anchor_id_trace = 1;

        let spaces = Array(20).fill('*');
        let tmp_menu = this.array_move(this.props.info, this.props.info.length - 1, 0);
        tmp_menu = this.array_move(this.props.info, this.props.info.length - 1, 0);

        tmp_menu.forEach(
            (obj) => {
                if (obj.name.length > 19) this.menus.push(obj.name.slice(0, 19) + '...');
                else {
                    let spaceNum = parseInt((23 - obj.name.length) / 2) - 1;
                    this.menus.push(spaces.slice(0, spaceNum).join("") + obj.name + spaces.slice(0, spaceNum).join(""));
                }
            }
        );
    }


    array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };

    isHovered = (event) => {
        this.props.handleHover(JSON.parse(event.getAttribute('data-objs')));
        this.is_hovered = true;
        if (this.f_time) this.f_time = false;
    }

    isClicked = (event) => {
        let data = JSON.parse(event.getAttribute('data-objs'));
        if (data !== '-') {
            let bg_color = window.getComputedStyle(event.parentNode).getPropertyValue('background-color');
            // document.getElementById('middle_').style.backgroundColor = bg_color;
            this.props.handleClick(bg_color, data);
        }
    }

    positioning = () => {
        this.spinner = document.getElementById('menu_nav1');
        let del = parseInt(window.innerWidth) - parseInt(this.spinner.offsetWidth);
        this.spinner.style.left = del / 2 + "px";
    }


    modify_anchor_trace = (boolean) => {
        if (boolean) {
            this.anchor_id_trace++;
            if (this.anchor_id_trace > this.props.info.length - 1) this.anchor_id_trace = 0;
        }
        else {
            this.anchor_id_trace--;
            if (this.anchor_id_trace < 0) this.anchor_id_trace = this.props.info.length - 1;
        }
    }


    componentDidMount() {

        var total = this.menus.length;
        var lastIndex = total - 1;
        var prevId = 5;
        var prevIndex = 4;
        var nextId = 7;
        var nextIndex = lastIndex;
        var rotate = 0;

        for (let i = 0; i < 4; i++) {
            let a_element = document.getElementById(`${i}`);
            a_element.innerHTML = this.menus[i];
            a_element.setAttribute('data-objs', JSON.stringify(this.props.info[i]));
            a_element.addEventListener('mouseenter',
                (e) => {
                    this.isHovered(e.currentTarget);
                }
            );
            a_element.addEventListener('mouseleave',
                () => {
                    this.is_hovered = false;
                }
            );
            a_element.addEventListener('click', (e) => {
                this.isClicked(e.currentTarget);
            });
        }

        // Positioning
        this.positioning();
        window.addEventListener("resize", this.positioning);

        document.querySelectorAll('ul.circle li a.over').forEach((e) => {
            updateCurvedText(e, 230);
        });


        this.prev_btn.current.addEventListener('click', () => {
            this.modify_anchor_trace(true);
            rotate -= 51.42;
            let rtext = 'rotate(' + rotate + 'deg)';
            document.getElementsByClassName('menunav')[0].style.transform = rtext;

            var text = this.menus[prevIndex];
            //text = text.replaceAll(' ','<br>');
            text = `<a class="over" id="${prevIndex}" href="javascript:void(0)">${text}</a>`;
            document.getElementById('m-' + prevId).innerHTML = text;

            let anchor = document.getElementById(`${prevIndex}`);
            anchor.setAttribute('data-objs', JSON.stringify(this.props.info[prevIndex]));
            anchor.addEventListener('mouseenter',
                (e) => {
                    this.isHovered(e.currentTarget);
                });
            anchor.addEventListener('mouseleave',
                () => {
                    this.is_hovered = false;
                }
            );

            anchor.addEventListener('click', (e) => {
                this.isClicked(e.currentTarget);
            });

            updateCurvedText(document.getElementById(prevIndex), 250);
            prevId++;
            if (prevId === 8) {
                prevId = 1;
            }
            prevIndex++;
            if (prevIndex > lastIndex) {
                prevIndex = 0;
            }

            // Next Index Adjust
            nextIndex++;
            if (nextIndex > lastIndex) {
                nextIndex = 0;
            }
            //Next Id Adjust
            nextId++;
            if (nextId === 8) {
                nextId = 1;
            }

        });

        this.next_btn.current.addEventListener('click', () => {
            this.modify_anchor_trace(false);
            rotate += 51.42;
            let rtext = 'rotate(' + rotate + 'deg)';

            document.getElementsByClassName('menunav')[0].style.transform = rtext;

            var text = this.menus[nextIndex];
            //text = text.replaceAll(' ','<br>');
            text = `<a class="over" id="${nextIndex}" href="javascript:void(0)">${text}</a>`;
            document.getElementById('m-' + nextId).innerHTML = text;

            let anchor = document.getElementById(`${nextIndex}`);
            anchor.setAttribute('data-objs', JSON.stringify(this.props.info[nextIndex]));
            anchor.addEventListener('mouseenter',
                (e) => {
                    this.isHovered(e.currentTarget);
                });
            anchor.addEventListener('mouseleave',
                () => {
                    this.is_hovered = false;
                }
            );
            anchor.addEventListener('click', (e) => {
                this.isClicked(e.currentTarget);
            });


            updateCurvedText(document.getElementById(nextIndex), 250);
            nextId--;
            nextIndex--;
            if (nextId === 0) {
                nextId = 7;
            }
            if (nextIndex < 0) {
                nextIndex = lastIndex;
            }
            // Prev Index Adjust
            prevIndex--;
            if (prevIndex < 0) {
                prevIndex = lastIndex;
            }
            //Next Id Adjust
            prevId--;
            if (prevId === 0) {
                prevId = 7;
            }
        });

        function updateCurvedText(curvedText, radius) {

            curvedText.style.minWidth = "initial";
            curvedText.style.minHeight = "initial";

            var w = curvedText.offsetWidth,
                h = curvedText.offsetHeight;

            curvedText.style.minWidth = w + "px";
            curvedText.style.minHeight = h + "px";

            var text = curvedText.innerText;
            var html = "";

            Array.from(text).forEach((letter) => {
                html += `<span>${letter}</span>`;
            });

            curvedText.innerHTML = html;

            var letters = curvedText.getElementsByTagName("span");
            letters = Array.prototype.slice.call(letters);


            var angleRad = w / (2 * radius);
            var angle = 2 * angleRad * 180 / Math.PI / text.length;
            var idx = 0;

            letters.forEach(
                (e) => {
                    if (e.innerText === '*') {
                        e.style.opacity = 0;
                    }
                    e.style.position = "absolute";
                    e.style.height = `${radius}px`;
                    e.style.transformOrigin = "bottom center";
                    e.style.transform = `translate(${w / 2}px, 8px) rotate(${1.06 * (idx * angle - text.length * angle / 2)}deg)`;
                    idx++;
                }
            );
        }


        document.querySelectorAll('ul.circle li label.circle').forEach((e) => {
            e.addEventListener('mouseover', (element) => {
                document.querySelectorAll('ul.circle li label.circle').forEach(
                    (el) => {
                        el.classList.remove('active');
                    }
                );
                e.classList.add('active');
            });
        });


        this.time_out = null;
        this.mouse_busy = false;
        this.is_at_bottom = false;
        this.is_on_map = true;

        this.f_time = true;
        this.mouseY = 0;
        this.Yboundary = (parseFloat(this.pxToRem()) * 5.3) + 50;

        this.auto_rotate_spinner();
        window.addEventListener('mousemove', this.mouse_busy_handler);
        this.scroll_event_handler();
    }

    pxToRem = () => {
        return parseInt(getComputedStyle(this.spinner).width.replace('px', '')) / 27;
    }

    mouse_busy_handler = (e) => {
        if (this.f_time) {
            (window.innerHeight - e.pageY) > this.Yboundary ? this.is_on_map = true : this.is_on_map = false;
        }

        this.mouse_busy = true;
        if (this.time_out !== null) window.clearTimeout(this.time_out);
        if (!this.is_hovered) {
            this.time_out = window.setTimeout(() => {
                this.mouse_busy = false;
            }, 4000);
        }

    }

    auto_rotate_spinner = () => {
        this.spinnerInterval = setInterval(() => {
            this.prev_btn.current.classList.add('highlight');
            this.next_btn.current.classList.add('highlight');

            if (!this.is_hovered && !this.mouse_busy && !this.is_on_map || this.f_time) {
                if (this.f_time) this.f_time = false;
                this.next_btn.current.click();
                this.props.handleHover(JSON.parse(
                    document.getElementById(`${this.anchor_id_trace}`).getAttribute('data-objs')
                ));
            }
            window.setTimeout(() => {
                this.prev_btn.current.classList.remove('highlight');
                this.next_btn.current.classList.remove('highlight');
            }, 800);
        }, 10000);
    }


    componentWillReceiveProps(nextProp) {
        this.is_on_map = nextProp.mouse_is_busy;
    }

    scroll_event_handler = () => {
        if (window.scrollY < 200 && this.is_at_bottom) {
            this.auto_rotate_spinner();
            this.is_at_bottom = false;
        }
        else if (window.scrollY > 200 && !this.is_at_bottom) {
            this.is_at_bottom = true;
            window.clearInterval(this.spinnerInterval);
        }
    }


    componentWillUnmount() {
        window.removeEventListener('mousemove', this.mouse_busy_handler);
        window.clearInterval(this.spinnerInterval);
        window.removeEventListener("scroll", this.scroll_event_handler);
        window.removeEventListener("resize", this.positioning);
    }



    render() {
        return (
            <div>
                <nav className="menunav" id='menu_nav1'>
                    <ul className="circle">

                        <li className="coconut light slice">
                            {/* Menu labels */}
                            <label htmlFor="ococonut" className="circle over" id="m-1">
                                <a className="over" id={0} href="javascript:void(0)" data-objs={JSON.stringify(this.props.info[0])}>1
                                </a>
                            </label>
                        </li>

                        <li className="vanilla light slice">
                            {/* Menu labels */}
                            <label htmlFor="ovanilla" className="circle over" id="m-2">
                                <a className="over" id={1} href="javascript:void(0)" data-objs={JSON.stringify(this.props.info[1])}>2
                                </a>
                            </label>
                        </li>

                        <li className="orange light slice" >
                            {/* Menu labels */}
                            <label htmlFor="oorange" className="circle over" id="m-3">
                                <a className="over" id={2} href="javascript:void(0)" data-objs={JSON.stringify(this.props.info[2])}>3</a>
                            </label>
                        </li>

                        <li className="almond light slice"  >
                            {/* Menu labels */}
                            <label htmlFor="oalmond" className="circle over" id="m-4">
                                <a className="over" id={3} href="javascript:void(0)" data-objs={JSON.stringify(this.props.info[3])}>4</a>
                            </label>
                        </li>

                        <li className="grape light slice"  >
                            {/* Menu labels */}
                            <label htmlFor="ogrape" className="circle over" id="m-5">
                                <a className="over" id={4} href="javascript:void(0)">5 </a>
                            </label>
                        </li>

                        <li className="blackberry light slice"  >
                            {/* Menu labels */}
                            <label htmlFor="oblackberry" className="circle over" id="m-6">
                                <a className="over" id={5} href="javascript:void(0)">6</a>
                            </label>
                        </li>

                        <li className="cherry light slice"  >
                            {/* Menu labels */}
                            <label htmlFor="ocherry" className="circle over" id="m-7">
                                <a className="over" id={6} href="javascript:void(0)">7 </a>
                            </label>
                        </li>

                        <li className="unsel circle">
                            <label htmlFor="unsel" className="clicky menuname" />
                        </li>
                        <li className="middle circle" />
                    </ul>

                </nav>
                <div className="col-md-12">
                    <div className="menu-navigate">
                        <button className="btn" id="prev_btn" ref={this.prev_btn}> <i className="fa fa-angle-left" /> </button>
                        <button className="btn" id="next_btn" ref={this.next_btn}> <i className="fa fa-angle-right" /> </button>
                    </div>
                </div>
            </div>

        );
    }
}


export default SpinnerCorner;


