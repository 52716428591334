import React from 'react';
// import Footer from '../../Shared/Footer/Footer';
// import Banner from './Banner/Banner';
// import GostDiv from './SelectHotailsOrHospital/GostDiv';
import SelectHotelsOrHospital from './SelectHotailsOrHospital/SelectHotelsOrHospital';


const Home = () => {
    return (
        <SelectHotelsOrHospital></SelectHotelsOrHospital>
    );
};

export default Home;