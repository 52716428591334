import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
// import logoWhite from './../../assets/Images/logoWhite.png'
// import logoBlack from './../../assets/Images/logoBlack.png'
// import logoYellow from './../../assets/Images/logoYellow.png'
import logoYellow from "./../../assets/Images/logo_new.png";
import { NavLink } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const [isSticky, setSticky] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    });
  }, []);

  return (
    <div>
      <Navbar
        bg="light"
        expand="lg"
        className="navBlue navbar  navbar-expand-lg navbar-dark  fixed-top navbar navbar-expand-lg navbar-light bg-light"
      >
        <Container>
          <Nav.Link as={NavLink} to="/home">
            <Navbar.Brand href="#home">
              <img src={logoYellow} className="p-2 headerLogo " alt="logo" />
            </Navbar.Brand>
          </Nav.Link>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto align-items-center">
              <Nav.Link as={NavLink} to="/home">
                Home
              </Nav.Link>
              <Nav.Link as={NavLink} to="/hospital">
                Hospitals
              </Nav.Link>
              <Nav.Link as={NavLink} to="/hotel">
                Hotels
              </Nav.Link>
              <a
                href="https://p5jfzp15q2p.typeform.com/to/iAHQysg7"
                target="_blank"
              >
                Contact
              </a>
              <Nav.Link as={NavLink} to="/about">
                About
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
