import React, { useState, useEffect } from 'react';
import './OurHospital.css'
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import HospitalDepartments from '../HospitalDepartments/HospitalDepartments';
import HospitalMapGostDiv from '../HospitalMap/HospitalMapGostDiv/HospitalMapGostDiv';
import SpinnerCorner from '../../Spinner/spinnerCorner';
import Header from './../../../Shared/Header/Header';
import messageIcon from "../../../assets/Images/message-icon.png"

const OurHospitals = () => {

    let [hospitalData, setHospitalData] = useState([]);
    let [mouse_busy, setMouse_busy] = useState(false);

    useEffect(
        () => {
            const ref = collection(db, 'Hospitals');
            const q = query(ref, orderBy('index', 'desc'));
            let items = [];
            getDocs(q).then(
                (querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        items.push({ ...doc.data(), id: doc.id });
                    });
                    setHospitalData(items);
                }
            );
        }, []
    );


    let [allPagesVisibility, setAllPagesVisibility] = useState(false);
    let [selectedHospital, setSelectedHospital] = useState({});
    let [departmentData, setDepartmentData] = useState({});
    const myRef = React.createRef();


    // on hover
    const handleSelectValue = (selectedHospitalData) => {
        setSelectedHospital(selectedHospitalData);
    }

    const HandleHoverButtonClick = (data) => {
        setAllPagesVisibility(!allPagesVisibility);
        setDepartmentData(data);
    }


    useEffect(
        () => {
            if (allPagesVisibility) {
                var screenh = window.innerHeight;
                window.scrollBy(0, screenh + 800);
            }
        }, [allPagesVisibility]
    );

    return (
        <>
            <Header />
            <div className="ourHospitals">
                {
                    hospitalData.length !== 0 ?
                        <div className='hospital_txt'>
                            Your Preferred Hospital
                        </div> : <></>
                }

                {
                    !selectedHospital?.id ?
                        <>
                            <div className="container pt-5">
                                <div className="col-12 pt-5" >
                                    <div className='pt-5'>
                                        <h3>Our Hospitals</h3>
                                        <h4>Look out for the hospitals you want to - spin and pin</h4>
                                    </div>
                                </div>
                            </div>


                        </> : <div className='map_container'>

                            <div className="mapContainer iFrameContainer">
                                <div style={{ width: '100vw', height: '100vh' }}>
                                    <iframe
                                        onMouseLeave={
                                            () => {
                                                setMouse_busy(false);
                                            }
                                        }
                                        onMouseOver={() => {
                                            setMouse_busy(true);
                                        }} src={selectedHospital?.map_hospital} width="100%" height="100%" title='Hotel and Hospital Map'>

                                    </iframe>
                                </div>
                            </div>

                            <div className='bottom-map-img'>
                            </div>
                        </div>
                }


                <div className="spin-wheel-corner">

                    {
                        hospitalData.length !== 0 ?
                            <SpinnerCorner info={hospitalData}
                                mouse_is_busy={mouse_busy}
                                handleClick={
                                    (_, data) => {
                                        HandleHoverButtonClick(data);
                                    }
                                }

                                handleHover={
                                    (data) => {
                                        handleSelectValue(data);
                                    }
                                }

                            /> : <></>
                    }

                </div>
            </div>

            {
                allPagesVisibility ?
                    <div ref={myRef}>
                        <HospitalMapGostDiv></HospitalMapGostDiv>
                        <HospitalDepartments hospitalData={departmentData} />
                    </div>
                    :
                    <> </>
            }


            <a href="https://p5jfzp15q2p.typeform.com/to/iAHQysg7" target="_blank" id="fixedbutton-link">
                <button className='btn-shadow' id='fixedbutton'>
                    <img src={messageIcon} className='img-fluid' alt="" />
                </button>
            </a>

        </>
    );
};

export default OurHospitals;
