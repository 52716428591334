import React, { useState } from 'react';
import Rooms from '../Rooms/Rooms';
// import HotelDepartment from './../../../assets/Images/HotelsDepertmentImage.png';
import SpinnerMiddle from '../../Spinner/spinnerMiddle';
import './HotelDepartments.css';


const HotelDepartments = ({ HotelData }) => {

    let bgColor = '#1c6d82';
    let [selectedDepartment, setSelectedDepartment] = useState('');
    let allPackages = [];

    for (let i = 0; i < HotelData?.packages?.length; i++) {
        allPackages.push(HotelData?.packages[i].name);
    }

    const roomData = HotelData?.packages?.filter(packages => packages?.name === selectedDepartment);


    const handleSelectValue = (selectedDepartment) => {
        setSelectedDepartment(selectedDepartment);
    }


    return (
        <div className={!selectedDepartment ? "departments" : "doctors hotelDiv"} style={{ backgroundColor: "#fff"}}>
            <div>
                {
                    allPackages.length !== 0 ?

                        !selectedDepartment ?
                            <div className="container spinner-cont">
                                <h1 className='text-center'>
                                    HOTEL ROOMS
                                </h1>

                            </div>
                            :
                            <Rooms
                                marginBottom={true}
                                roomData={roomData}
                                HotelData={HotelData}
                                bg_color={bgColor}
                            /> :
                        <></>
                }
                <>

                    {
                        allPackages.length === 0 ?
                            <Rooms
                                marginBottom={false}
                                roomData={[
                                    {
                                        name: " "
                                    }
                                ]}
                                HotelData={HotelData}
                                bg_color={bgColor}
                            />
                            :
                            <>
                                <div className='hospital_txt hospital_txt_2'>
                                    Select Your <br /> Room Category
                                </div>
                                <div className='spinner-middle-cont' style={{ backgroundColor: "#ffffff" }}>
                                    <SpinnerMiddle info={allPackages}
                                        handleClick={
                                            (data) => {
                                                handleSelectValue(data);
                                            }
                                        }
                                    />
                                </div>
                            </>
                    }

                </>
            </div>

        </div >

    );
};

export default HotelDepartments;